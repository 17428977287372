import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-order-completed',
  templateUrl: './order-completed.component.html',
  styleUrls: ['./order-completed.component.scss'],
})
export class OrderCompletedComponent implements OnInit {
  @Input("order") order;
  constructor(
    private modalController: ModalController,
    private router: Router,
  ) { }

  ngOnInit() {
    console.log(this.order)
  }

  modalDismiss(){
    this.modalController.dismiss();
    this.router.navigate([
      '/tabs/profile/feedback',
      { orderid: this.order.book_request_id,
      mechanicid :this.order.mechanic_id,
      user_id:this.order.user_id },
    ]);
  }

}
