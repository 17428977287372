import {Component, OnInit} from '@angular/core';
import {Platform, NavController} from '@ionic/angular';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {Router, ActivatedRoute} from '@angular/router';
import {FirebaseAnalytics} from '@ionic-native/firebase-analytics/ngx';
import {AuthService} from 'src/app/services/auth.service';
import {Storage} from '@ionic/storage';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit {
  version: string = '2.7.3';
  showSocial: boolean = false;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private appVersion: AppVersion,
    private firebaseAnalytics: FirebaseAnalytics,
    private iab: InAppBrowser,
    private storage: Storage,
    public route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.firebaseAnalytics.logEvent('welcome', {content_type: 'speaker', page: 'welcome'});
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {});
    this.platform.ready().then((res) => {
      var showDate = new Date(2026, 6, 26, 0, 0, 0, 0);
      var currentDate = new Date();
      if (showDate.getTime() < currentDate.getTime()) {
        this.showSocial = true;
      }
    });
  }

  openLink() {
    const url = `https://www.youtube.com/watch?v=uGskmh3mE8M`;
    this.iab.create(url);
  }

  forgotPassword() {
    this.navCtrl.navigateForward('/auth/forgot-password');
  }

  login(data: any) {
    const loginData = {
      ...data,
      platform: this.platform.is('ios') ? 'iOS' : 'Android',
      fcm_token: '',
      contact_no: '',
    };
    this.authService.socialLogin(loginData).subscribe((res) => {
      if (res && res.status) {
      } else {
        this.authService.userInfo.next(loginData);
        this.navCtrl.navigateForward('/signup');
      }
    });
  }

  moveToNext(selectOption: String) {
    this.storage.set('USER_GROUP', selectOption);
    if (selectOption == '4') this.navCtrl.navigateForward('/signup/mechanic-setting-info');
    // this.navCtrl.navigateForward('/signup/business-address');
    else this.navCtrl.navigateForward('/signup');
  }
}
