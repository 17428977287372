import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AlertController, NavController } from '@ionic/angular';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { Storage } from '@ionic/storage';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { Platform } from '@ionic/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';

@Component({
  selector: 'app-build-quotelogin',
  templateUrl: './build-quotelogin.page.html',
  styleUrls: ['./build-quotelogin.page.scss'],
})
export class BuildQuoteloginPage implements OnInit {
  form: FormGroup;
  loginData: any = {};
  msg: any;
  data: any;
  userId: any;
  username: any;
  mechanic_name: any;
  notifications: any;
  requestId: any;

  constructor(
    public navCtrl: NavController, public storage: Storage,
    private route: ActivatedRoute,
    private authService: AuthService,
    private alertCtrl: AlertController,
    // public storage: Storage,
    private router: Router,
    public auth: AuthService, private firebaseAnalytics: FirebaseAnalytics,
    public appService: AppService,
    private appAvailability: AppAvailability,
    private platform: Platform,
    private appVersion: AppVersion
  ) {
    this.form = new FormGroup({
      password: new FormControl('', Validators.required),
      Confirm_password: new FormControl('', Validators.required)
    });
  }
  ngOnInit() {
    this.data = this.route.snapshot.paramMap.get('data');

    const params = this.data.slice(1).split("?");
    let userId: string | null = null;
    let username: string | null = null;
    let mechanicName: string | null = null;

    // Process each parameter
    params.forEach(param => {
      const [key, value] = param.split("=");

      // URL decode the value
      const decodedValue = decodeURIComponent(value);

      // Check the key and assign values accordingly
      if (key === "id") {
        this.userId = decodedValue;
      } else if (key === "username") {
        const names = decodedValue.split('%20');
        this.username = names.join(' '); // Join the names with a space
      } else if (key === "mechanic_name") {
        this.mechanic_name = decodedValue;
      } else if (key === "bid") {
        this.requestId = decodedValue;
      }
    });

    // Output the results
  }

  // ionViewDidEnter() {
  //   this.openAppOrWeb();
  // }
  // 
  onLogin() {

    if (this.form.invalid) {
      this.appService.presentErrorToast('Please fill all required fields.');
      return;
    }
    if (this.form.value.Confirm_password != this.form.value.password) {
      this.appService.presentErrorToast('Password and confirm password should be same');
      return;
    }
    this.loginData = this.form.value;
    this.firebaseAnalytics.logEvent('login', { content_type: 'speaker', id: this.form.value.email })

    const para = {
      user_id: this.userId,
      password: this.form.value.password,
    }
    // this.appService.presentLoader();

    this.auth.build_QuoteLogin(para).subscribe((res: any) => {
      if (res.status) {
        const data = {
          email: res.user.email,
          password: this.form.value.password,
        }

        this.dologin(data);
        this.appService.dismissLoader();
        if (this.form.valid) {
          localStorage.removeItem('fingerData');
        } else {
          setTimeout(() => {

          }, 1000);
        }
      }
    },
      error => {

        //   this.storage.get('msg_login_err').then(msgdata => {
        //   this.msg =msgdata;
        //   this.appService.dismissLoader();
        //   this.appService.presentErrorToast('Email and password does not match.');
        // })
      }
    );
  }

  dologin(data) {
    this.appService.presentLoader();
    this.auth.dobuildLogin(data,this.requestId).subscribe(res => {

      if (this.requestId) {
        this.navCtrl.navigateForward(['/tabs/alerts/repair-estimate', this.requestId]);
      } else {
        this.router.navigate(['tabs/home']);
      }
      // this.getNotificationList();
    }, error => {
      this.storage.get('msg_login_err').then(msgdata => {
        this.msg = msgdata;
        this.appService.dismissLoader();
        this.appService.presentErrorToast('Email and password does not match.');
      })
    }
    );
  }

  // getNotificationList() {
  //   this.auth.consumerNotifications().subscribe((res: any) => {
  //     if (res.status) {
  //       this.appService.dismissLoader();
  //       this.notifications = res.data;
  //       for (let i in this.notifications) {
  //         if (this.notifications[i].consumer_id == this.userId.toString()) {
  //           this.requestId = this.notifications[i].book_request_id;
  //         }
  //       }
  //       if (this.requestId) {
  //         this.navCtrl.navigateForward(['/tabs/alerts/repair-estimate', this.requestId]);
  //       } else {
  //         this.router.navigate(['tabs/home']);
  //       }
  //     }
  //   });
  // }

  showRecoverAccountAlert() {
    this.alertCtrl.create({
      header: 'We found a previous account that used this same email address. Do you want to recover this account?',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            this.storage.set('email', this.form.get('email')?.value);
            this.storage.set('is_recovery', 1);
            this.navCtrl.navigateForward('/signup/recover-account-security');
          }
        },
        {
          text: 'No',
          handler: () => {
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  openAppOrWeb() {
    const appName = 'com.automonkeyapp';
    if (this.platform.is('cordova')) {
      this.appAvailability.check(appName).then((yes: boolean) => {
        alert('yes ' + yes);
        window.location.href = 'com.automonkeyapp://'; // Replace with the custom URL scheme of the native app
      }, (no: boolean) => {
        alert('no ' + no);
        // Native app is not available, open web app
        window.open('https://mobile-app.theautomonkey.com/', '_system');
      });
    } else {
      // Cordova is not available, open web app
      window.open('https://mobile-app.theautomonkey.com/', '_system');
    }
  }



}
