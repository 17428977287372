export const environment = {
  production: false,
  
  CHAT_IMAGE_BASE_URL: 'https://dashboard-staging.theautomonkey.com/uploads/job_chat_image/',
  BASE_URL: 'https://dashboard-staging.theautomonkey.com/',
  LEGACY_API_URL: 'https://dashboard-staging.theautomonkey.com/api/v13/',
  LEGACY_HOST: 'dashboard-staging.theautomonkey.com',
  HOST: 'hrhg5ktsd2.us-east-2.awsapprunner.com',
  API_URL: 'https://hrhg5ktsd2.us-east-2.awsapprunner.com/',

  // BASE_URL: 'http://3.133.21.78/',//Staging
  //CHAT_IMAGE_BASE_URL: 'https://3.133.21.78/uploads/job_chat_image/',//Staging
  // API_URL:  'http://3.133.21.78/api/v10/',//Staging
  //BASE_URL: 'http://automonkey.apptomate.xyz/',
  //API_URL: 'http://automonkey.apptomate.xyz/api/v2/',

  ONESIGNAL_APPID: '5852c380-adec-456e-81df-0cc0bbcf4365',
  ONESIGNAL_GOOGLE_NUMBER: '703322744261',
  PUSHER_URL: 'https://app.theautomonkey.com/pusher/auth',
  PUSHER_KEY: 'd4255351238767cbd61b',
  PUSHER_CLUSTER: 'mt1',
  POSTHOG_KEY: 'phc_evYwZyLWapCRlnW7Vy9FNOUEkPCU10FHE1QjuiWSY71',
  POSTHOG_HOST: 'https://us.i.posthog.com',
  LOGTAIL: 'MbZwFUevGqTgBafRzaqFaEB7',
};
